import React from 'react';
import * as Render from '../../platformui/Base/Render';
import BuildInfo from '../../platformui/BuildInfo';
import AccessibilityNav from '../../platformui/AccessibilityNav';
import Header from '../../platformui/Header';
import Footer from '../../platformui/Footer';
import CookiePanel from '../../platformui/CookiePanel';
import Spinner from '../../platformui/Spinner';
import ScrollToTop from '../../platformui/ScrollToTop';
import HeaderTools from '../../components/c-header-tools';
import { LangSelectorSmall } from '../../components/c-langselector';
import PanicButton from '../../components/c-panic-button';
import { isMediumViewport } from '../../platformui/Hooks/useMedia';
import SurveyEntry from '../../platformui/SurveyEntry';

const AccessibilityShortcuts = (props) => {
  return (
    <AccessibilityNav>
      <li>
        <a href="#content">Till sidans huvudinnehåll</a>
      </li>
      {props.PageTypeName === 'SearchPage' && (
        <li>
          <a href="#search">Till sökresultatet</a>
        </li>
      )}
    </AccessibilityNav>
  );
};

const Layout = (props) => {
  const activeSurvey = props.SurveyEntries?.find(survey => survey.IsActive);
  return (
    <div>
      <PanicButton
        key="panicbtn"
        {...props.PanicButton}
        Translations={props.Content.Translations}
      />
      <AccessibilityShortcuts key="shortcuts" {...props} />
      <BuildInfo key="buildinfo" BuildInfo={props.BuildInfo} />
      <Header
        {...props}
        key="header"
        readingAid={props.ReadingAid.EnableReadingAid}
        Spinner={Spinner}
        Logo={props.Logo}
        link={props.StartPageLink}
        Tools={HeaderTools}
        complimentnavHeading={props.Site === 'umo'}
        HasSearch={props.Site === 'umo'}
        LoadBlocks={(callback) => {
          import('../../blocks').then((blocks) => callback(blocks));
        }}
        LangSelectorSmall={props.Site === 'youmo' && LangSelectorSmall}
      />
      <div key="main-wrapper" className="main-wrapper">
        {activeSurvey && <SurveyEntry {...activeSurvey} />}
        <props.PageTypeRenderer key="asyncpages" {...props} />
        <ScrollToTop key="scroll-top" {...props} />
      </div>
      <Footer
        {...props}
        key="footer"
        FooterLogo={props.LogoUmo}
        ShowLogo={props.SiteFooter.ShowLogotype || isMediumViewport()}
        Modifiers={{ 'c-linklist--white': true }}
      />
      {!props.CookiePro.Enabled && (
        <CookiePanel key="cookie-panel" {...props} />
      )}
    </div>
  );
};

export default Render.Root(Layout);
